import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cart: [],
    checkOutValue: false,
    ischeckoutButtonDisabled: false,
    cartShippingDetails: [],
    cardDetails: [],
    cartBrandCalc: [],
    failedOrders: [],
    successOrders: []
};

const CartSlice = createSlice({
    name: 'CartSlice',
    initialState,
    reducers: {
        resetToInitial: (state) => {state = initialState;},
        setCheckOutValue: (state, action) => {
            state.checkOutValue = action.payload;
        },
        setIscheckoutButtonDisabled: (state, action) => {
            state.ischeckoutButtonDisabled = action.payload;
        },
        setCart: (state, action) => {
            state.cart = [...action.payload];
        },
        setFailedOrders: (state, action) => {
            state.failedOrders = [...action.payload];
        },
        setSuccessOrders: (state, action) => {
            state.successOrders = [...action.payload];
        },
        setCartShippingDetails: (state, action) => {
            state.cartShippingDetails = action.payload;
        },
        setCardDetails: (state, action) => {
            state.cardDetails = action.payload;
        },
        setCartBrandCalc: (state, action) => {
            state.cartBrandCalc = action.payload;
        },
        updateCartQty: (state, action) => {

            if(action.payload.cart)
            {
                let cartId = action.payload.cart.id;
                let itemId = action.payload.item.id;
                let carts = state.cart
                let qty = action.payload.qty;
                carts.forEach(function(cart, cartKey) {

                    if(cart.id == cartId)
                    {

                        cart.cart_items.forEach(function(cartItem, cartItemKey) {
                            if(cartItem.id == itemId)
                            {       

                                // if(qty >= 0)
                                // {
                                    carts[cartKey].cart_items[cartItemKey].cart_quantity = parseInt(qty)

                                // }
                                // carts[cartKey][cartItemKey].cart_quantity  = qty

                            }

                        })

                    }
                    
                });

                state.cart = carts;
                // console.log('cartItem',JSON.stringify(carts))



                // console.log('action',JSON.stringify(state.cart));


            }
            // state.successOrders = [...action.payload];
        }
    },
});

/**
 * Actions
 */
export const {
    resetToInitial,
    setCheckOutValue,
    setIscheckoutButtonDisabled,
    setCart,
    setCartShippingDetails,
    setCardDetails,
    setCartBrandCalc,
    setFailedOrders,
    setSuccessOrders,
    updateCartQty
} = CartSlice.actions;

/**
 * Reducers
 */
export const cartReducer = CartSlice.reducer;
