import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bottomMsg: null,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setBottomNotification: (state, action) => {
      state.bottomMsg = action.payload;
    }
  },
});

/**
 * Actions
 */
export const {
  setBottomNotification,
} = notificationSlice.actions;

/**
 * Reducers
 */
export const notificationReducer = notificationSlice.reducer;
