import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    retailerChannelFilter: {
        filterByBrand: [],
        statusViseFilter: [],
        stockFilter: [],
        daysFilter:  [],
        priceFilter: 'WSP',
        minPriceValue: '',
        maxPriceValue: '',
    },
    productSearchQuery: '',

    setActiveOpenProduct:false,
    channels:[],
    products:[],

};

const RetailerChannelSlice = createSlice({
    name: 'RetailerChannelSlice',
    initialState,
    reducers: {
        setBrandFilter: (state, action) => {
            state.retailerChannelFilter.filterByBrand = action.payload;
        },

        setStatusWiseFilter: (state, action) => {
            state.retailerChannelFilter.statusViseFilter = action.payload;
        },

        setStockWiseFilter: (state, action) => {
            console.log('action',action.payload);
            state.retailerChannelFilter.stockFilter = action.payload;
        },
        setDayWiseFilter: (state, action) => {
            state.retailerChannelFilter.daysFilter = action.payload;
        },
        setPriceWiseFilter: (state, action) => {
            state.retailerChannelFilter.priceFilter = action.payload;
        },
        setAllTimeMinPrice: (state, action) => {
            console.log('action.payload',action.payload);
            state.retailerChannelFilter.minPriceValue = action.payload;
        },
        setAllTimeMaxPrice: (state, action) => {
            state.retailerChannelFilter.maxPriceValue = action.payload;
        },

        resetToInitial: (state) => {
            state.retailerChannelFilter = {
                filterByBrand: [],
                statusViseFilter: [],
                stockFilter: [],
                daysFilter: [],
                minPriceValue: '',
                maxPriceValue: '',
                priceFilter:'WSP'
            };
        },

        setRetailerChannels: (state, action) => {
            state.channels = action.payload;
        },

        setRetailerChannelProducts: (state, action) => {
            state.products = action.payload;
        },

        setProductSearchQuery: (state, action) => {
            console.log('action.payload',action.payload);
            state.productSearchQuery = action.payload;
          },


    },
});

/**
 * Actions
 */
export const {
    setBrandFilter,
    resetToInitial,
    setStatusWiseFilter,
    setStockWiseFilter,
    setDayWiseFilter,
    setPriceWiseFilter,
    setRetailerChannels,
    setRetailerChannelProducts,
    setProductSearchQuery,
    setAllTimeMinPrice,
    setAllTimeMaxPrice
} = RetailerChannelSlice.actions;

/**
 * Reducers
 */
export const retailerChannelReducer = RetailerChannelSlice.reducer;
