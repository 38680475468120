import { isLoggedIn } from '../redux/auth/authSelector';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const AuthGuard = (Component) => {
  const isLogged = useSelector(isLoggedIn);
  if (isLogged) {
    return <Component />;
  }
  return <Navigate to="/login" />
};
