import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoggedIn: false,
  isLoginSuccess: false,
  isLoginError: false,
  loginError: null,
  isRegisterSuccess: false,
  isVerifyEmailSent: false,
  user: null,
  access_token: '',
  refresh_token: '',
  redirectAfterLogin: "",
  statusIndicator: {
    billing: false,
    products: false,
    store: false,
    onboarding: false,
  },
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLoggedIn = false;
      state.access_token = '';
      state.refresh_token = '';
    },
    setLoggedIn: (state, action) => {
      state.isLoginSuccess = true;
      state.isLoggedIn = true;
    },
    setLoginError: (state, action) => {
      state.isLoginError = true;
      state.loginError = action.payload;
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
    },
    resetLoginStateRequest: (state, action) => {
      state.isLoginError = false;
      state.loginError = null;
      state.isLoginSuccess = false;
      state.isLoggedIn = false;
    },
    setToken: (state, action) => {
      state.access_token = action.access_token;
      state.refresh_token = action.refresh_token;
    },
    setRegister: (state, action) => {
      state.isRegisterSuccess = true;
      state.isLoggedIn = true;
    },
    setVerifyEmail: (state, action) => {
      state.isVerifyEmailSent = false;
    },
    setRedirectAfterLogin: (state, action) => {
      state.redirectAfterLogin = action.payload;
    },
    clearAuthReducer: (state, action) => {
      state.isLoginSuccess = false;
      state.isRegisterSuccess = false;
      state.isVerifyEmailSent = false;
    },
    clearAuthLogout: (state) => {
      state = initialState;
    },
    setStatusIndicator: (state, action) => {
      state.statusIndicator = action.payload;
    },
  },
});

/**
 * Actions
 */
export const {
  logOut,
  setLoggedIn,
  setRegister,
  clearAuthReducer,
  setToken,
  clearAuthLogout,
  setStatusIndicator,
  setLoginError,
  resetLoginStateRequest,
  setRedirectAfterLogin,
} = authSlice.actions;

/**
 * Reducers
 */
export const authReducer = authSlice.reducer;
