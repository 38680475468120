import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'; // imports from redux-persist
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { authReducer } from './auth/authSlice';
import { gettingPaidReducer } from './Brand/GettingPaid2/gettingPaidSlice';
import { userReducer } from './user/userSlice';
import { securityReducer } from './Brand/Security/securitySlice';
import { shippingReducer } from './Brand/Shipping/shippingSlice';
import { orderReducer } from './Brand/Orders/orderSlice';
import { brandProfileReducer } from './Brand/Profile/brandProfileSlice';
import { productReducer } from './Brand/Products/productSlice';
import { preferenceReducer } from './Brand/Preference/preferenceSlice';
import { getCountriesReducer } from './General/Countries/getCountriesSlice';
import { getStatesReducer } from './General/States/getStatesSlice';
import { retailerProfileReducer } from './Retailer/Profile/retailerProfileSlice';
import { retailerSecurityReducer } from './Retailer/Security/securitySlice';
import { retailerProductReducer } from './Retailer/Brand/Products/retailerBrandProductsSlice';
import { retailerReducer } from './Brand/Retailer/retailerSlice';
import { brandRetailerProfileReducer } from './Brand/RetailerProfile/retailerProfileSlice';
import { getCategoryReducer } from './General/Category/getCategorySlice';
import { notificationReducer } from './notification/notificationSlice';
import { brandSubusersReducer } from './Brand/Users/brandUsersSlice';
import { cartReducer } from './Retailer/Cart/CartSlice';
import { retailerOrderReducer } from './Retailer/Orders/RetailerOrderSlice';

import { brandPlansReducer } from './Brand/Plans/brandPlansSlice';
import { retailerPlansReducer } from './Retailer/Plans/retailerPlansSlice';
import { retailerChannelReducer } from './Retailer/Channels/RetailerChannelsSlice';
import { salesRepReducer } from './Brand/SalesRep/salesRepSlice';
 
import { retailerStoreReducer } from './Retailer/Store/RetailerStoreSlice';
 
import { martReducer } from './Mart/Products/ProductsSlice';

import { RetailerStorefrontReducer } from './Retailer/Storefront/storeFrontSlice';
 
const persistConfig = {
  // configuration object for redux-persist
  key: 'root',
  storage, // define which storage to use
  transforms: [
    encryptTransform({
      secretKey: process.env.REACT_APP_SECRET_KEY_REDUX || 'shopdot',
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

const combinedReducer = combineReducers({
  auth: authReducer,
  gettingPaid: gettingPaidReducer,
  user: userReducer,
  security: securityReducer,
  shipping: shippingReducer,
  brandOrders: orderReducer,
  brandProfile: brandProfileReducer,
  brandProduct: productReducer,
  preference: preferenceReducer,
  countries: getCountriesReducer,
  states: getStatesReducer,
  retailerProfile: retailerProfileReducer,
  brandRetailerProfile: brandRetailerProfileReducer,
  retailerSecurity: retailerSecurityReducer,
  brandRetailer: retailerReducer,
  retailerProduct: retailerProductReducer,
  category: getCategoryReducer,
  notification: notificationReducer,
  brandSubusers: brandSubusersReducer,
  retailerCart: cartReducer,
  retailerOrders: retailerOrderReducer,
  brandPlans:brandPlansReducer,
  retailerPlans:retailerPlansReducer,
  retailerChannel:retailerChannelReducer,
  salesRep:salesRepReducer,
 
  retailerStore:retailerStoreReducer,
  retailerStorefront:RetailerStorefrontReducer,
  productMart: martReducer,
 
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return combinedReducer(undefined, { type: undefined });
  } else {
    return combinedReducer(state, action);
  }
};

// TODO: for future use - if we want to remove store from localstorage
// const rootReducer = (state, action) => {
//
//     // when a logout action is dispatched it will reset redux state
//     if (action.type === 'auth/logOut') {
//         state = undefined;
//     }

//     return appReducer(state, action);
// };

const persistedReducer = persistReducer(persistConfig, rootReducer); // create a persisted reducer

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

const persistor = persistStore(store); // used to create the persisted store, persistore will be used in the next step

export { store, persistor };
