import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  changePasswordLoading: false,
  changePasswordRequesting: false,
  changePasswordSuccess: false,
  changePasswordFailure: false,
  changePasswordError: null,
};

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {
    onChangePassword: (state, action) => {
      state.changePasswordLoading = action.payload;
    },
    clearSecurityLogout: (state) => {
      state = initialState;
    },
    setChangePasswordRequest: (state, action) => {
      state.changePasswordRequesting = true;
    },
    setChangePasswordSuccess: (state, action) => {
      state.changePasswordRequesting = false;
      state.changePasswordSuccess = true;
      state.changePasswordFailure = false;
      state.changePasswordError = null;
    },
    setChangePasswordFailure: (state, action) => {
      state.changePasswordRequesting = false;
      state.changePasswordSuccess = false;
      state.changePasswordFailure = true;
      state.changePasswordError = action.payload;
    },
    resetChangePasswordRequestState: (state, action) => {
      state.changePasswordRequesting = false;
      state.changePasswordSuccess = false;
      state.changePasswordFailure = false;
      state.changePasswordError = null;
    },
  },
});

/**
 * Actions
 */
export const {
  onChangePassword,
  clearSecurityLogout,
  setChangePasswordRequest,
  setChangePasswordSuccess,
  setChangePasswordFailure,
  resetChangePasswordRequestState,
} = securitySlice.actions;

/**
 * Reducers
 */
export const securityReducer = securitySlice.reducer;
