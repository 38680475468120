import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  retailerProfileSaving: false,
  retailerProfileSaveResult: null,
  retailerProfileStore: null,
  retailerProfileCompleted: {
    profile: false,
    paid: false,
    integration_shop: false,
    plan:false,
    is_subscription_active:false,
    subscription_details:{},
    shop_detail:{},
    is_onboarding_completed:false,
    active_plan:{}
  },
  
};

const retailerProfileSlice = createSlice({
  name: 'retailerProfile',
  initialState,
  reducers: {
    setRetailerProfileSaving: (state, action) => {
      state.retailerProfileSaving = true;
    },
    setRetailerProfileSaveResult: (state, action) => {
      state.retailerProfileSaving = false;
      state.retailerProfileSaveResult = action.payload;
    },
    setRetilerProfileCompleted: (state, action) => {
      state.retailerProfileCompleted = {
        ...state.retailerProfileCompleted,
        ...action.payload,
      };
    },
    setRetilerProfileStore: (state, action) => {
      console.log('action',action.payload.RetailerStoreData);
      state.retailerProfileStore = action.payload.RetailerStoreData;
    },
  },
});

/**
 * Actions
 */
export const {
  setRetailerProfileSaving,
  setRetailerProfileSaveResult,
  setRetilerProfileCompleted,
  setRetilerProfileStore,
} = retailerProfileSlice.actions;

/**
 * Reducers
 */
export const retailerProfileReducer = retailerProfileSlice.reducer;
