import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // Pending Sub Users
  fetchPendingSubusersRequesting: false,
  pendingSubusersFetchSuccess: false,
  pendingSubusersFetchFailure: false,
  pendingSubusersFetchError: null,
  pendingSubusers: [],
  // All Sub Users
  fetchAllSubusersRequesting: false,
  allSubusersFetchSuccess: false,
  allSubusersFetchFailure: false,
  allSubusersFetchError: null,
  allSubusers: [],
  // Add Sub User
  addSubuserRequesting: false,
  addSubuserSuccess: false,
  addSubuserFailure:false,
  addSubuserError: null,
  addSubuserResult: null,
  // Delete Sub User
  deleteSubuserRequesting: false,
  deleteSubuserSuccess: false,
  deleteSubuserFailure:false,
  deleteSubuserError: null,
  deleteSubuserResult: null,
  // Update Sub User
  updateSubuserRequesting: false,
  updateSubuserSuccess: false,
  updateSubuserFailure:false,
  updateSubuserError: null,
  updateSubuserResult: null,
};

const brandSubusersSlice = createSlice({
  name: 'brandSubUsersSlice',
  initialState,
  reducers: {
    // Pending Subusers
    fetchPendingSubusers: (state, action) => {
        state.fetchPendingSubusersRequesting = true;
    },
    setPendingSubusersFetchSuccess: (state, action) => {
        state.fetchPendingSubusersRequesting = false;
        state.pendingSubusersFetchSuccess = true;
        state.pendingSubusersFetchError = null;
        state.pendingSubusersFetchFailure = false;
        state.pendingSubusers = action.payload;
    },
    setPendingSubusersFetchFailure: (state, action) => {
        state.fetchPendingSubusersRequesting = false;
        state.pendingSubusersFetchSuccess = false;
        state.pendingSubusersFetchError = action.payload;
        state.pendingSubusersFetchFailure = true;
        state.pendingSubusers = null;
    },
    resetPendingSubusersRequest: (state, action) => {
        state.fetchPendingSubusersRequesting = false;
        state.pendingSubusersFetchSuccess = false;
        state.pendingSubusersFetchError = null;
        state.pendingSubusersFetchFailure = false;
    },
    // All Subusers
    fetchAllSubusers: (state, action) => {
        state.fetchAllSubusersRequesting = true;
    },
    setAllSubusersFetchSuccess: (state, action) => {
        state.fetchAllSubusersRequesting = false;
        state.allSubusersFetchSuccess = true;
        state.allSubusersFetchError = null;
        state.allSubusersFetchFailure = false;
        state.allSubusers = action.payload;
    },
    setAllSubusersFetchFailure: (state, action) => {
        state.fetchAllSubusersRequesting = false;
        state.allSubusersFetchSuccess = false;
        state.allSubusersFetchError = action.payload;
        state.allSubusersFetchFailure = true;
    },
    resetAllSubusersRequest: (state, action) => {
        state.fetchAllSubusersRequesting = false;
        state.allSubusersFetchSuccess = false;
        state.allSubusersFetchError = null;
        state.allSubusersFetchFailure = false;
    },
    // Add Subuser
    addSubuser: (state, action) => {
        state.addSubuserRequesting = true;
    },
    setAddSubuserSuccess: (state, action) => {
        state.addSubuserRequesting = false;
        state.addSubuserSuccess = true;
        state.addSubuserFailure = null;
        state.addSubuserError = false;
        state.addSubuserResult = action.payload;
    },
    setAddSubuserFailure: (state, action) => {
        state.addSubuserRequesting = false;
        state.addSubuserSuccess = false;
        state.addSubuserFailure = true;
        state.addSubuserError = action.payload;
        state.addSubuserResult = null;
    },
    resetAddSubuserRequest: (state, action) => {
        state.addSubuserRequesting = false;
        state.addSubuserSuccess = false;
        state.addSubuserFailure = false;
        state.addSubuserError = null;
        state.addSubuserResult = null;
    },
    // delete Subuser
    deleteSubuser: (state, action) => {
        state.deleteSubuserRequesting = true;
    },
    setDeleteSubuserSuccess: (state, action) => {
        state.deleteSubuserRequesting = false;
        state.deleteSubuserSuccess = true;
        state.deleteSubuserFailure = false;
        state.deleteSubuserError = null;
        state.deleteSubuserResult = action.payload;
    },
    setDeleteSubuserFailure: (state, action) => {
        state.deleteSubuserRequesting = false;
        state.deleteSubuserSuccess = false;
        state.deleteSubuserFailure = true;
        state.deleteSubuserError = action.payload;
        state.deleteSubuserResult = null;
    },
    resetDeleteSubuserRequest: (state, action) => {
        state.deleteSubuserRequesting = false;
        state.deleteSubuserSuccess = false;
        state.deleteSubuserFailure = false;
        state.deleteSubuserError = null;
        state.deleteSubuserResult = null;
    },
    // Update Subuser
    updateSubuser: (state, action) => {
        state.updateSubuserRequesting = true;
    },
    setUpdateSubuserSuccess: (state, action) => {
        state.updateSubuserRequesting = false;
        state.updateSubuserSuccess = true;
        state.updateSubuserFailure = false;
        state.updateSubuserError = null;
        state.updateSubuserResult = action.payload;
    },
    setUpdateSubuserFailure: (state, action) => {
        state.updateSubuserRequesting = false;
        state.updateSubuserSuccess = false;
        state.updateSubuserFailure = true;
        state.updateSubuserError = action.payload;
        state.updateSubuserResult = null;
    },
    resetUpdateSubuserRequest: (state, action) => {
        state.updateSubuserRequesting = false;
        state.updateSubuserSuccess = false;
        state.updateSubuserFailure = false;
        state.updateSubuserError = null;
        state.updateSubuserResult = null;
    }
  },
});

/**
 * Actions
 */
export const {
    fetchPendingSubusers,
    setPendingSubusersFetchSuccess,
    setPendingSubusersFetchFailure,
    setPendingSubusersFetchError,
    resetPendingSubusersRequest,
    //
    fetchAllSubusers,
    setAllSubusersFetchSuccess,
    setAllSubusersFetchFailure,
    setAllSubusersFetchError,
    resetAllSubusersRequest,
    //
    addSubuser,
    setAddSubuserSuccess,
    setAddSubuserFailure,
    setAddSubuserError,
    resetAddSubuserRequest,
    //
    deleteSubuser,
    setDeleteSubuserSuccess,
    setDeleteSubuserFailure,
    setDeleteSubuserError,
    resetDeleteSubuserRequest,
    //
    updateSubuser,
    setUpdateSubuserSuccess,
    setUpdateSubuserFailure,
    setUpdateSubuserError,
    resetUpdateSubuserRequest,
} = brandSubusersSlice.actions;

/**
 * Reducers
 */
export const brandSubusersReducer = brandSubusersSlice.reducer;
