import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessDetails: {},
  representativeDetails: {},
  bankDetails: {},
  paidDetails: {},
  gettingPaidPreferance: {},
  gettingPaidEditStatus: false,
};

const gettingPaidSlice = createSlice({
  name: 'gettingPaid',
  initialState,
  reducers: {
    setBusinessDetails: (state, action) => {
      state.businessDetails = action.payload;
    },
    setRepresentativeDetails: (state, action) => {
      state.representativeDetails = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankDetails = action.payload;
    },
    setPaidCompleted: (state, action) => {
      state.bankDetails = action.payload;
    },
    setPaidDetails: (state, action) => {
      console.log(action.payload, 'action.payload');
      state.paidDetails = action.payload;
    },
    setGettingPaidPreferance: (state, action) => {
      state.gettingPaidPreferance = action.payload;
    },
    setGettingPaidEditStatus: (state, action) => {
      console.log(action, 'action');
      state.gettingPaidEditStatus = action.payload;
    },
    gettingPaidResetToInitial: (state) => {
      state.businessDetails = {};
      state.representativeDetails = [];
      state.bankDetails = {};
      state.gettingPaidPreferance = {};
    },
    resetToInitial: (state) => {
      state.businessDetails = {};
      state.representativeDetails = {};
      state.bankDetails = {};
      state.gettingPaidPreferance = {};
    },
    clearPaidLogout: (state) => {
      state = initialState;
    },
  },
});

/**
 * Actions
 */
export const {
  setBusinessDetails,
  setRepresentativeDetails,
  setPaidCompleted,
  setBankDetails,
  resetToInitial,
  setPaidDetails,
  setGettingPaidPreferance,
  clearPaidLogout,
  gettingPaidResetToInitial,
  setGettingPaidEditStatus,
} = gettingPaidSlice.actions;

/**
 * Reducers
 */
export const gettingPaidReducer = gettingPaidSlice.reducer;
