import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  subHeaderOption: '',
  activeLink: 0,
  searchProductItem: '',
  cart: false,
  storeDetails: [],
  storeProducts: [],
  brandFilters: [],
  storeProductDetails: [],
  storeBrands: [],
  storeCartDetails: [],
  storeCustomerInfo: [],
  storeOrderStatus: [],
  storeCountries: [],
  storeStates: [],
};

const martSlice = createSlice({
  name: 'martSlice',
  initialState,
  reducers: {
    setStoreDetails: (state, action) => {
      state.storeDetails = action.payload;
    },
    setStoreBrands: (state, action) => {
      state.storeBrands = action.payload;
    },
    setStoreProducts: (state, action) => {
      state.storeProducts = action.payload;
    },
    setStoreProductDetails: (state, action) => {
        state.storeProductDetails = action.payload;
      },
    setStoreBrandFilter: (state, action) => {
      state.brandFilters = action.payload;
    },
    setSubHeader: (state, action) => {
      state.subHeaderOption = action.payload;
    },
    setSearchProductItem: (state, action) => {
      state.searchProductItem = action.payload;
    },
    setStoreActiveLink: (state, action) => {
      state.activeLink = action.payload;
    },
    setStoreCartDetails: (state, action) => {
      state.storeCartDetails = action.payload;
    },
    setStoreCustomerInfo: (state, action) => {
      state.storeCustomerInfo = action.payload;
    },
    setStoreOrderStatus: (state, action) => {
      state.storeOrderStatus = action.payload;
    },
    setStoreCountries: (state, action) => {
      state.storeCountries = action.payload;
    },
    setStoreStates: (state, action) => {
      state.storeStates = action.payload;
    },
    openCart: (state, action) => {
      state.cart = action.payload;
      console.log('action.payload', action.payload);
    },
  },
});

/**
 * Actions
 */
export const {
  setSubHeader,
  setSearchProductItem,
  setStoreOrderStatus,
  openCart,
  setStoreCountries,
  setStoreStates,
  setStoreCustomerInfo,
  setStoreActiveLink,
  setStoreBrands,
  setStoreDetails,
  setStoreBrandFilter,
  setStoreProducts,
  setStoreCartDetails,
  setStoreProductDetails
} = martSlice.actions;

/**
 * Reducers
 */
export const martReducer = martSlice.reducer;
