//sign up and sign in
export const SEGMENT_SIGN_IN = "Sign In Completed";
export const SEGMENT_SIGN_UP = "Sign Up Completed";
//brand
export const SEGMENT_PERSONALISE_COMPLETED_BRAND = "Personalise & Brand Completed";
export const SEGMENT_BRAND_PROFILE_COMPLETED = "Brand Profile Completed";
export const SEGMENT_BRAND_GETTING_PAID_COMPLETED = "Brand: Getting Paid Settings Completed";
export const SEGMENT_BRAND_MANDATORY_SETTING_COMPLETED = "Brand: Mandatory Settings Completed";
export const SEGMENT_ACTIVE_PRODUCT_COMPLETED = "Active Product Completed";
export const SEGMENT_FULLY_ACTIVE_COMPLETED = "Fully Active Completed";
export const SEGMENT_CURRENTLY_INACTIVE = "Currently Inactive";
export const SEGMENT_RETAILER_CONNECTION_COMPLETED = "Retailer Connection Completed";
export const SEGMENT_SHOPIFY_CONNECTED = "Shopify Connected";
export const SEGMENT_SHOPIFY_DISCONNECTED = "Shopify Disconnected";
export const SEGMENT_ALLOW_ONLINE_SELLING = "Allow Online Selling";
//retailer
export const SEGMENT_RETAOLER_PROFILE_COMPLETED = "Retailer Profile Completed";
export const SEGMENT_PERSONALISE_COMPLETED_RETAILER = "Personalise & Retailer Completed";
export const SEGMENT_RETAILER_MANDATORY_SETTING_COMPLETED = "Retailer: Mandatory Settings Completed";
export const SEGMENT_BRAND_CONNECTION_COMPLETED = "Brand Connection Completed";
export const SEGMENT_RETAILER_SHOPIFY_CONNECTED = "Retailer Shopify Connected";
export const SEGMENT_RETAILER_SHOPIFY_DISCONNECTED = "Retailer Shopify Disconnected";

export const SEGMENT_RETAILER_GETTING_PAID_COMPLETED = "Retailer: Getting Paid Settings Completed";
export const SEGMENT_RETAILER_STORE_SETTING = "Store Setting Completed";
export const SEGMENT_RETAILER_STORE_SETTING_ONBOARDING = "Store Setting Completed";
