import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    storeFilter: {
        statusFilter: [],
        brandFilter: [],
        stockFilter: [],
        daysToFulfillFilter: [],
        wspFilter: [],
        msrpFilter: [],
    },
    statusOptions: ['Active', 'Inactive'],
    brandsOptions: ['Brand 1', 'Brand 2', 'Brand 3', 'Brand 4'],
    stockOptions: ['0', '1 to 10', '11 to 50', '50+'],
    daysToFulfillOptions: [
        '0 to 7 days',
        '8 to 14 days',
        '15 to 21 days',
        '>21 days',
    ],
    wspOptions: ['$1 - $99', '$100 - $499', '$500 - $999', '$1,000 or more'],
    msrpOptions: ['$1 - $15', '$15 - $50', '$50 - $500', '$1,000 or more'],
    storeDataList: [],
    storeCategoryList: [],


    brandFilters: [],
    // dynamic filter states
    selectedBrandFilters: [],
};

const retailerStoreSlice = createSlice({
    name: 'retailerStoreSlice',
    initialState,
    reducers: {
        setStoreFilter: (state, action) => {
            state.storeFilter = action.payload;
        },
        resetToInitialStore: (state) => {
            state.storeFilter = {
                statusFilter: [],
                brandFilter: [],
                stockFilter: [],
                daysToFulfillFilter: [],
                wspFilter: [],
                msrpFilter: [],
            };
        },
        statusFilterClear: (state) => {
            state.storeFilter.statusFilter = [];
        },
        brandFilterClear: (state) => {
            state.storeFilter.brandFilter = [];
        },
        stockFilterClear: (state) => {
            state.storeFilter.stockFilter = [];
        },
        daysToFulfillFilterClear: (state) => {
            state.storeFilter.daysToFulfillFilter = [];
        },
        wspFilterClear: (state) => {
            state.storeFilter.wspFilter = [];
        },
        msrpFilterClear: (state) => {
            state.storeFilter.msrpFilter = [];
        },
        setStoreProductList: (state, action) => {
            state.storeDataList = action.payload;
        },
        setStoreCategoryList: (state, action) => {
            state.storeCategoryList = action.payload;
        },

        setBrandFilters: (state, action) => {
            state.brandFilters = action.payload;
        },
        setSelectedBrandFilters: (state, action) => {
            state.selectedBrandFilters = [...action.payload];
        },
    },
});

/**
 * Actions
 */
export const {
    setStoreFilter,
    resetToInitialStore,
    statusFilterClear,
    brandFilterClear,
    stockFilterClear,
    daysToFulfillFilterClear,
    wspFilterClear,
    msrpFilterClear,
    setStoreProductList,
    setStoreCategoryList,

    setBrandFilters,
    //Filter
    setSelectedBrandFilters,
} = retailerStoreSlice.actions;

/**
 * Reducers
 */
export const retailerStoreReducer = retailerStoreSlice.reducer;
