import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  orderFilter: {
    brandFilter: [],
    paymentFiter: [],
    fulfillmentFiter: [],
    dateRange: {},
    chennels: [],
    brandOrderStatusFilter:[],
    orderStatusFilter: [],
  },
  retailerOptions: ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven'],
  chennelsOptions: ['ShopDot App', 'Shopify'],
  paymentOptions: ['paid', 'refund'],
  fulFillmentOptions: [
    'fulfilled',
    'unfulfilled',
    'cancelled',
  ],
  retailerOrders: [],
  orderCount: 0,
  limit: 100,
  offset: 0,
  selectedBrandFilters: [],
  selectedPaymentFilters: [],
  selectedFullFillmentFilters: [],
  chanelsFilter: [],
  selectedFromDate: '',
  selectedToDate: '',
  retrailerOrderSearchFilter: '',
  retailerOrderReportDownloading: false,
  retailerOrderReportDownlodSuccess: false,
  retailerOrderReportDownlodFailure: false,
  retailerOrderReportDownlodError: null,
  retailerPendingOrders: [],
  pendingOrderCount: 0,
  requiredActionOrderCount: 0,
  selectedBrandOrderStatusFilter:[],
  selectedOrderStatusFilter: [],
  retailerOrderChannels: []

};

const retailerOrderSlice = createSlice({
  name: 'retailerOrderSlice',
  initialState,
  reducers: {
    setOrderFilter: (state, action) => {
      state.orderFilter = action.payload;
    },
    resetToInitial: (state) => {
      state.orderFilter = {
        brandFilter: [],
        paymentFiter: [],
        fulfillmentFiter: [],
        dateRange: {},
        chennels: [],
        selectedBrandOrderStatusFilter: [],
        selectedOrderStatusFilter: [],


      };
    },
    retailerClear: (state) => {
      state.orderFilter.brandFilter = [];
    },
    paymentClear: (state) => {
      state.orderFilter.paymentFiter = [];
    },
    fulFillmentClear: (state) => {
      state.orderFilter.fulfillmentFiter = [];
    },
    dateRangeClear: (state) => {
      state.orderFilter.dateRange = {};
    },
    setRetailerOrderChannels: (state, action) => {
      state.retailerOrderChannels = action.payload;
    },
    chennelsClear: (state) => {
      state.orderFilter.chennels = [];
    },
    setRetailerOrders: (state, action) => {
      state.retailerOrders = action.payload.rows;
      state.orderCount = action.payload.count >= 0 ? action.payload.count : 0;
    },
    setSelectedBrandFilters: (state, action) => {
      state.selectedBrandFilters = [...action.payload];
    },
    setSelectedPaymentFilters: (state, action) => {
      state.selectedPaymentFilters = [...action.payload];
    },
    setSelectedFullFillmentFilters: (state, action) => {
      state.selectedFullFillmentFilters = [...action.payload];
    },
    setSelectedChanelsFilters: (state, action) => {
      state.selectChanelsFilters = [...action.payload];
    },
    setSelectedFromDate: (state, action) => {
      state.selectedFromDate = action.payload;
    },
    setSelectedToDate: (state, action) => {
      state.selectedToDate = action.payload;
    },
    setRetailerOrderReportDownloading: (state, action) => {
      state.retailerOrderReportDownloading = true;
    },
    setRetailerOrderReportSuccess: (state, action) => {
      state.retailerOrderReportDownloading = false;
      state.retailerOrderReportDownlodSuccess = true;
      state.retailerOrderReportDownlodFailure = false;
      state.retailerOrderReportDownlodError = null;
    },
    setRetailerOrderReportFailure: (state, action) => {
      state.retailerOrderReportDownloading = false;
      state.retailerOrderReportDownlodSuccess = false;
      state.retailerOrderReportDownlodFailure = true;
      state.retailerOrderReportDownlodError = action.payload;
    },
    resetRetailerOrderReportState: (state, action) => {
        state.retailerOrderReportDownloading = false;
        state.retailerOrderReportDownlodSuccess = false;
        state.retailerOrderReportDownlodFailure = false;
        state.retailerOrderReportDownlodError = null;
      },
    setOffset: (state, action) => {
      state.offset = action.payload;
    },
    setOrderLimit: (state, action) => {
      state.limit = action.payload;
    },
    setRetrailerOrderSearchFilter: (state, action) => {
      state.retrailerOrderSearchFilter = action.payload;
    },
    setRetailerPendingOrders: (state, action) => {
      state.retailerPendingOrders = action.payload.rows;
      state.pendingOrderCount = action.payload.count >= 0 ? action.payload.count : 0;
    },
    setRequiredActionOrderCount: (state, action) => {
      state.requiredActionOrderCount = action.payload;
    },
    setBrandOrderStatusFilter: (state, action) => {
      state.selectedBrandOrderStatusFilter = action.payload;
    },
    setOrderStatusFilter: (state, action) => {
      state.selectedOrderStatusFilter = action.payload;
    },
    brandOrderStatusClear: (state) => {
      state.orderFilter.brandOrderStatusFilter = [];
    },
    orderStatusClear: (state) => {
      state.orderFilter.orderStatusFilter = [];
    },
    
  },
});

/**
 * Actions
 */
export const {
  setOrderFilter,
  resetToInitial,
  setRetailerOrderChannels,
  retailerClear,
  paymentClear,
  fulFillmentClear,
  dateRangeClear,
  chennelsClear,
  setRetailerOrders,
  setSelectedBrandFilters,
  setSelectedPaymentFilters,
  setSelectedFullFillmentFilters,
  setSelectedChanelsFilters,
  setSelectedFromDate,
  setSelectedToDate,
  setRetailerOrderReportDownloading,
  setRetailerOrderReportSuccess,
  setRetailerOrderReportFailure,
  resetRetailerOrderReportState,
  setOffset,
  setOrderLimit,
  setRetrailerOrderSearchFilter,
  setRetailerPendingOrders,
  setRequiredActionOrderCount,
  setBrandOrderStatusFilter,
  setOrderStatusFilter,
  brandOrderStatusClear,
  orderStatusClear
} = retailerOrderSlice.actions;

/**
 * Reducers
 */
export const retailerOrderReducer = retailerOrderSlice.reducer;
