export const selectProductFilter = (state) => state.brandProduct.productFilter;

export const selectProductCategory = (state) =>
  state.brandProduct.productCatOptions;

export const selectProductCategoryPerRetailer = (state) =>
  state.brandProduct.productCatPerRetailerOptions;

export const selectProductTags = (state) =>
  state.brandProduct.productTagOptions;

export const selectProductTagsPerRetailer = (state) =>
  state.brandProduct.productTagPerRetailerOptions;

export const selectStockOptions = (state) => state.brandProduct.stockOptions;

export const selectBrandProductList = (state) =>
  state.brandProduct.brandProductList;

export const selectProductCatFilter = (state) =>
  state.brandProduct.productCatFilter;

export const selectProductTagFilter = (state) =>
  state.brandProduct.productTagFilter;

export const selectStockFilter = (state) => state.brandProduct.stockFilter;

export const selectProductStatusFilter = (state) =>
  state.brandProduct.productStatusFilter;

export const selectProductRetailerFilter = (state) =>
  state.brandProduct.productRetailerFilter;

export const selectProductRetailerListFilter = (state) =>
  state.brandProduct.productRetailerListFilter;

export const selectProductDetails = (state) =>
  state.brandProduct.productDetails;

export const selectUpdatingProduct = (state) =>
  state.brandProduct.updatingProduct;

export const selectProductUpdateResult = (state) =>
  state.brandProduct.productUpdateResult;

export const selectUpdateProductSuccess = (state) =>
  state.brandProduct.updateProductSuccess;

export const selectProductSubCatOptions = (state) =>
  state.brandProduct.productSubCatOptions;

export const selectProductGroupOptions = (state) =>
  state.brandProduct.productGroupOptions;

export const selectSyncError = (state) => state.brandProduct.syncError;

export const selectSyncInProgress = (state) =>
  state.brandProduct.syncInProgress;

export const selectSyncSuccess = (state) => state.brandProduct.syncSuccess;

export const selectProductSubCatAndGroupOptions = (state) =>
  state.brandProduct.productSubCatAndGroupOptions;

export const selectUploadFileStatus = (state) => state.brandProduct.uploadFileStatus;
export const selectUploadFileStatusCount = (state) => state.brandProduct.uploadFileStatusCount;
export const selectUploadFileStatusErrror = (state) => state.brandProduct.uploadFileStatusError;
export const selectProductSearchVal = (state) => state.brandProduct.productSearchVal;