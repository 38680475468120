import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productCatFilter: [],
  productTagFilter: [],
  stockFilter: [],
  productStatusFilter: [],
  productRetailerFilter: [],
  productRetailerListFilter: [],
  productSearchVal: "",
  productCatOptions: [],
  productCatPerRetailerOptions: [],
  productTagOptions: [],
  productTagPerRetailerOptions: [],
  stockOptions: ['0 units', '1-10 units', '11-50 units', '> 50 units'],
  brandProductList: [],
  productDetails: null,
  //Product Update
  updatingProduct: false,
  updateProductSuccess: false,
  productUpdateResult: null,
  productSubCatOptions: [],
  productGroupOptions: [],
  syncInProgress: false,
  syncError: null,
  syncSuccess: false,
  productSubCatAndGroupOptions: [],
  uploadFileStatus: false,
  uploadFileStatusCount: 0,
  uploadFileStatusError: []
};

const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    resetToInitial: (state) => {
      state.productCatFilter = [];
      state.productTagFilter = [];
      state.stockFilter = [];
    },
    productCatClear: (state) => {
      state.productCatFilter = [];
    },
    productTagClear: (state) => {
      state.productTagFilter = [];
    },
    stockClear: (state) => {
      state.stockFilter = [];
    },
    statusViseClear: (state) => {
      state.productFilter.statusViseFilter = [];
    },
    clearProductLogout: (state) => {
      state = initialState;
    },
    setBrandProductList: (state, action) => {
      state.brandProductList = action.payload;
    },
    setProductCatOptions: (state, action) => {
      state.productCatOptions = action.payload;
    },
    setProductSubCatAndGroupOptions: (state, action) => {
      state.productSubCatAndGroupOptions = action.payload;
    },
    setProductTagOptions: (state, action) => {
      state.productTagOptions = action.payload;
    },
    setProductCatPerRetailerOptions: (state, action) => {
      state.productCatPerRetailerOptions = action.payload;
    },
    setProductTagPerRetailerOptions: (state, action) => {
      state.productTagPerRetailerOptions = action.payload;
    },
    setProductCatFilter: (state, action) => {
      state.productCatFilter = [...action.payload];
    },
    setProductTagsFilter: (state, action) => {
      state.productTagFilter = [...action.payload];
    },
    setStockFilter: (state, action) => {
      state.stockFilter = [...action.payload];
    },
    setProductStatusFilter: (state, action) => {
      if(state.productStatusFilter !== "all" || action.payload !== 'all')
        state.productStatusFilter = action.payload;
    },
    setProductRetailerFilter: (state, action) => {
        state.productRetailerFilter = action.payload;
    },
    setProductRetailerListFilter: (state, action) => {
        state.productRetailerListFilter = action.payload;
    },
    setProductDetails: (state, action) => {
      state.productDetails = action.payload;
    },
    clearProductDetails: (state, action) => {
      state.productDetails = null;
    },
    setUpdatingProduct: (state, action) => {
      state.updatingProduct = true;
    },
    setProductUpdateResult: (state, action) => {
      state.updatingProduct = false;
      state.updateProductSuccess = true;
      state.productUpdateResult = action.payload;
    },
    setProductSubCatOptions: (state, action) => {
      state.productSubCatOptions = action.payload;
    },
    setProductGroupOptions: (state, action) => {
      state.productGroupOptions = action.payload;
    },
    setSyncError: (state, action) => {
      state.syncInProgress = false;
      state.syncError = action.payload;
    },
    setSyncSuccess: (state, action) => {
      state.syncInProgress = false;
      state.syncSuccess = true;
      state.syncError = null;
    },
    setSyncInProgress: (state, action) => {
      state.syncInProgress = true;
    },
    setResetSyncSuccess: (state, action) => {
      state.syncInProgress = false;
      state.syncSuccess = false;
      state.syncError = false;
    },
    setUploadFileStatus: (state) => {
      state.uploadFileStatus = !state.uploadFileStatus
    },
    setUploadFileStatusCount: (state,action) => {
      state.uploadFileStatusCount = action.payload
    },
    clearUpdateStatus:(state) => {
      state.uploadFileStatus = false;
      state.uploadFileStatusCount = 0
    },
    setUploadFileStatusError: (state,action)=>{
      state.uploadFileStatusError = action.payload
    },
    setProductSearchVal: (state,action)=>{
      state.productSearchVal = action.payload
    }
  },
});

/**
 * Actions
 */
export const {
  resetToInitial,
  productTagClear,
  productCatClear,
  stockClear,
  statusViseClear,
  clearProductLogout,
  setProductCatOptions,
  setProductCatPerRetailerOptions,
  setBrandProductList,
  setProductRetailerListFilter,
  setProductTagOptions,
  setProductTagPerRetailerOptions,
  setProductCatFilter,
  setProductTagsFilter,
  setStockFilter,
  setProductStatusFilter,
  setProductRetailerFilter,
  setProductDetails,
  setProductSubCatOptions,
  setProductGroupOptions,
  setSyncError,
  setSyncSuccess,
  setSyncInProgress,
  setResetSyncSuccess,
  clearProductDetails,
  setProductSubCatAndGroupOptions,
  setUploadFileStatus,
  setUploadFileStatusCount,
  clearUpdateStatus,
  setUploadFileStatusError,
  setProductSearchVal
} = orderSlice.actions;

/**
 * Reducers
 */
export const productReducer = orderSlice.reducer;
