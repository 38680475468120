import request from 'axios';
import { REFRESH_TOKEN, LOGOUT, SIGN_IN, REGISTER, ADMIN_USER_LOGIN } from '../constants/api';
import { isStoreFront } from '../helpers/helper';

const axios = request;
let requestQueue = [];
let isGenratingNewToken = false;

axios.interceptors.request.use(
  async (config) => {
    if (!config.headers.Authorization) {
      const token = await localStorage.getItem('accessToken');
      axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
      config.headers.Authorization = `Bearer ${token}`;
      config.maxRedirects = 0;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  async (response) => {
    if (response.config.url === SIGN_IN || response.config.url === REGISTER) {
      const token = response.data.data['access_token'] ? response.data.data['access_token'] : '';
      const refreshToken = response.data.data['refresh_token'] ? response.data.data['refresh_token'] : '';
      axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
      const userId = response.data.data['id'] ? response.data.data['id'] : '';

      try {
        localStorage.setItem('user_id', `${userId}`);
        localStorage.setItem('accessToken', token);
        localStorage.setItem('refreshToken', refreshToken);
      } catch (error) { }
    }
    return response;
  },
  async (error) => {
    if (isStoreFront()) {
      return true;
    }
    else
    { 
      if (
        error.response &&
        error.response.config &&
        error.response.config.url === REFRESH_TOKEN &&
        (error.response.status == 401 || error.response.status == 403)
      ) {
        const device_id = localStorage.getItem('device_id');
        sessionStorage.clear();
        localStorage.clear();
  
        if (device_id) {
          localStorage.setItem('device_id', device_id);
        }
  
        if (error.response.status == 403) {
          setTimeout(() => {
            window.location.href = '/login';
          }, 1000);
        }
        return false;
      } else if (error.response && error.response.status == 401) {
        const refreshToken = localStorage.getItem('refreshToken');
        const userId = localStorage.getItem('user_id');
        const deviceId = localStorage.getItem('device_id');
  
        const data = {
          token: refreshToken,
          user_id: userId,
          device_id: deviceId,
        };
  
        if (!refreshToken) {
            window.location.href = '/login';
        }
        requestQueue.push(error.config);
  
        return new Promise(async (resolve, reject) => {
          if (!isGenratingNewToken) {
            isGenratingNewToken = true;
            delete axios.defaults.headers.common['Authorization'];
  
            axios
              .post(REFRESH_TOKEN, data, {
                headers: { 'content-type': 'application/json' },
              })
              .then(async (response) => {
                axios.defaults.headers.common['Authorization'] = `Bearer ${response?.data?.data?.access_token}`;
                localStorage.setItem('accessToken', response?.data?.data?.access_token);
  
                requestQueue.map(async (req, key) => {
                  requestQueue[key].headers.Authorization = `Bearer ${response.data.data.access_token}`;
                  if (req.url == LOGOUT) {
                    const newData = JSON.parse(req.data);
                    localStorage.removeItem('accessToken');
                    req.data = JSON.stringify(newData);
                  }
                  axios(req)
                    .then((res) => resolve(res))
                    .catch((err) => reject(err));
                });
  
                isGenratingNewToken = false;
                requestQueue = [];
                location.reload();
              })
              .catch((error) => {
                setTimeout(async () => {
                  localStorage.removeItem('accessToken');
                }, 2000);
                if (error.response.status !== 500) {
                  return error;
                }
              });
          }
        });
      } else if (error.response && error.response.data && error.response.data.location) {
        window.location = error.response.data.location;
      } else {
        return Promise.reject(error);
      }
    }
   
  }
);

export default axios;