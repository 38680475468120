import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  statusViseFilter: [],
  categoryFilter: [],
  stateFilter: [],
  salesFilter: {},
  retailerRequests: [],
  connectedRetailers: [],
  allTimeSale: {min: '', max: '', activeTab: 'wsp'},
  retailers: null,
  brandAssignedRetailerUpdating: false,
  brandAssignedRetailerUpdateSuccess: false,
  brandAssignedRetailerUpdateError: false,
  retailerProductDetails: null,
  brandFilters: [],
  storeBrandFilters: [],
  // dynamic filter states
  selectedBrandFilters: [],
  selectedBrandStatusFilters: [],
  selectedStatusFilters: [],
  selectedDaysToFullfilFilters: [],
  selectedStockFilters: [],
  selectedWSPFilter: [],
  selectedMSRPFilter: [],
  selectedTagsValueFilter: [],
  selectedSubMenuCatgory: "",
  selectedBrandSubMenuCatgory: "",
  selectedSubCatgory: [],
  limit: 96,
  offset: 0,
  productSearchQuery: '',
  connectedRetailerSearchVal: '',
  disconnectedRetailerSearchVal: '',
  retailerList: [],
  selectedRetailerFilters: [],
  selectedSalesRepFilters: [],
  selectedFullfillmentFilters: [],
  fromDateFilter: '',
  toDateFilter: '',
  searchFilter: '',
  categoryBrandsList: [],
};

const retaielrSlice = createSlice({
  name: 'brandRetailer',
  initialState,
  reducers: {
    setStatusViseFilter: (state, action) => {
      state.statusViseFilter = [action.payload];
    },
    setCategoryFilter: (state, action) => {
      state.categoryFilter = [...action.payload];
    },
    setStateFilter: (state, action) => {
      state.stateFilter = [...action.payload];
    },
    setSalesFilter: (state, action) => {
      state.salesFilter = { ...action.payload };
    },
    resetConnecteRetailersFilter: (state) => {
      state.categoryFilter = [];
      state.stateFilter = [];
      state.salesFilter = {};
    },
    setRetailerRequests: (state, action) => {
      state.retailerRequests = action.payload.data;
    },
    setConnectedRetailers: (state, action) => {
      state.connectedRetailers = action.payload.data;
    },
    resetToInitial: (state) => {
      state.statusViseFilter = [];
    },
    setRetailers: (state, action) => {
      state.retailers = action.payload;
    },
    setBrandAssignedRetailersUpdating: (state) => {
      state.brandAssignedRetailerUpdating = true;
    },
    setBrandAssignedRetailerSuccess: (state) => {
      state.brandAssignedRetailerUpdating = false;
      state.brandAssignedRetailerUpdateSuccess = true;
      state.brandAssignedRetailerUpdateError = false;
    },
    setBrandAssignedRetailerError: (state) => {
      state.brandAssignedRetailerUpdating = false;
      state.brandAssignedRetailerUpdateSuccess = false;
      state.brandAssignedRetailerUpdateError = true;
    },
    resetBrandAssignedRetailerState: (state) => {
      state.brandAssignedRetailerUpdating = false;
      state.brandAssignedRetailerUpdateSuccess = false;
      state.brandAssignedRetailerUpdateError = false;
    },
    setRetailerProductDetails: (state, action) => {
      state.retailerProductDetails = action.payload;
    },
    setBrandFilters: (state, action) => {
      state.brandFilters = action.payload;
    },
    setStoreBrandFilters: (state, action) => {
      state.storeBrandFilters = action.payload;
    },
    setAllTimeSaleFilters: (state, action) => {
      state.allTimeSale = action.payload;
    },
    setSelectedBrandFilters: (state, action) => {
      // console.log('action.payload',action.payload);
      state.selectedBrandFilters = [...action.payload];
    },
    setSelectedBrandStatusFilters: (state, action) => {
      state.selectedBrandStatusFilters = [...action.payload];
    },
    setSelectedStatusFilters: (state, action) => {
      state.selectedStatusFilters = [...action.payload];
    },

    setSelectedDaysToFullfilFilters: (state, action) => {
      state.selectedDaysToFullfilFilters = [...action.payload];
    },
    setSelectedStockFilters: (state, action) => {
      state.selectedStockFilters = [...action.payload];
    },
    setSelectedWSPFilter: (state, action) => {
      state.selectedWSPFilter = [...action.payload];
    },
    setSelectedMSRPFilter: (state, action) => {
      state.selectedMSRPFilter = [...action.payload];
    },
    setSelectedTagsValueFilter: (state, action) => {
      state.selectedTagsValueFilter = [...action.payload];
    },
    setSelectedSubCatgory: (state, action) => {
      state.selectedSubCatgory = [...action.payload];
    },
    setSelectedSubMenuCatgory: (state, action) => {
      state.selectedSubMenuCatgory = action.payload;
    },
    setSelectedBrandSubMenuCatgory: (state, action) => {
      state.selectedBrandSubMenuCatgory = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    setOffset: (state, action) => {
      state.offset = action.payload;
    },
    setProductSearchQuery: (state, action) => {
      state.productSearchQuery = action.payload;
    },
    setRetailerList: (state, action) => {
      state.retailerList = action.payload;
    },
    setSelectedRetailerFilters: (state, action) => {
      state.selectedRetailerFilters = [...action.payload];
    },
    setSelectedSalesRepFilters: (state, action) => {
      state.selectedSalesRepFilters = action.payload;
    },
    setToDateFilter: (state, action) => {
      state.toDateFilter = action.payload;
    },
    setSearchFilter: (state, action) => {
      state.searchFilter = action.payload;
    },
    setFromDateFilter: (state, action) => {
      state.fromDateFilter = action.payload;
    },
    setSelectedFullfillmentFilters: (state, action) => {
      state.selectedFullfillmentFilters = [...action.payload];
    },
    selectCategoryBrandList: (state, action) => {
      state.categoryBrandsList = [...action.payload.data];
    },
    setConnectedRetailerSearchVal: (state,action)=>{
      state.connectedRetailerSearchVal = action.payload
    },
    setDisconnectedRetailerSearchVal: (state,action)=>{
      state.disconnectedRetailerSearchVal = action.payload
    }
  },
});

export const {
  setCategoryFilter,
  setStateFilter,
  setSalesFilter,
  resetToInitial,
  setStatusViseFilter,
  resetConnecteRetailersFilter,
  setRetailerRequests,
  setConnectedRetailers,
  setRetailers,
  setBrandAssignedRetailersUpdating,
  setBrandAssignedRetailerSuccess,
  setBrandAssignedRetailerError,
  resetBrandAssignedRetailerState,
  setRetailerProductDetails,
  setBrandFilters,
  setStoreBrandFilters,
  //Filter
  setSelectedBrandFilters,
  setAllTimeSaleFilters,
  setSelectedBrandStatusFilters,
  setSelectedStatusFilters,
  setSelectedDaysToFullfilFilters,
  setSelectedStockFilters,
  setSelectedWSPFilter,
  setSelectedMSRPFilter,
  setSelectedTagsValueFilter,
  setLimit,
  setOffset,
  setProductSearchQuery,
  setRetailerList,
  setSelectedRetailerFilters,
  setSelectedSalesRepFilters,
  setToDateFilter,
  setFromDateFilter,
  setSelectedFullfillmentFilters,
  selectCategoryBrandList,
  setSelectedSubCatgory,
  setSelectedSubMenuCatgory,
  setSelectedBrandSubMenuCatgory,
  setConnectedRetailerSearchVal,
  setDisconnectedRetailerSearchVal,
  setSearchFilter
} = retaielrSlice.actions;

/**
 * Reducers
 */
export const retailerReducer = retaielrSlice.reducer;
