import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // RetailerStoreDetails: {},
    retailerStoreSaving: false,
    checkSubDomain: false,
    retailerStore: null,
    category: [],
};

const StorefrontSlice = createSlice({
    name: 'Storefront',
    initialState,
    reducers: {
        // setRetailerStoreDetails: (state, action) => {
        //     // if (action.payload.type === types.BRAND_PROFILE) {
        //       state.RetailerStoreDetails = action.payload;
        //     // }
        // },
        setRetailerStoreSaving: (state, action) => {
            state.retailerStoreSaving = true;
        },
        setCheckSubDomain: (state, action) => {
            state.checkSubDomain = true;
        },
        setRetailerStore: (state, action) => {

            // console.log('111--->....', action.payload);
            state.retailerStore = action.payload;
        },
        setCategory: (state, action) => {
            state.category.push(action.payload);
        },
        setDeleteCategory: (state, action) => {
            state.category = state.category.filter(
                (_, index) => index !== action.payload
            );
        },
        resetToInitial: (state) => {
            state.category = [];
        },
        setAllStoreCategorys: (state, action) => {
            state.category = action.payload ? action.payload : [];
        },
    },
});

/**
 * Actions
 */
export const {
    // setRetailerStoreDetails,
    setRetailerStoreSaving,
    setCheckSubDomain,
    setRetailerStore,
    setCategory,
    setDeleteCategory,
    resetToInitial,
    setAllStoreCategorys
} =
    StorefrontSlice.actions;

/**
 * Reducers
 */
export const RetailerStorefrontReducer = StorefrontSlice.reducer;
