import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activePlan: {},
    percentage: [],
    plans:[],
    activePlanPrice: 0,
    weightage: 0,

    billingInfo: {},
    invoices:[],
    subscriptionInfo: {},
};

const brandPlansSlice = createSlice({
    name: 'brandPlans',
    initialState,
    reducers: {
        setSelectPlan: (state, action) => {
            state.activePlan = action.payload;
        },
        setPercentage: (state, action) => {
            const newValue = action.payload;
            if (!state.percentage.includes(newValue)) {
                state.percentage.push(newValue);
            }
        },
        setPlans: (state, action) => {
            state.plans = action.payload;
        },
        setActivePlanPrice:(state,action) => {
            state.activePlanPrice = action.payload;
        },
        setBillingInfo: (state, action) => {
            state.billingInfo = action.payload;
        },
        setBillingInvoices: (state, action) => {
            state.invoices = action.payload;
        },
        setActivePlanWeightage:(state,action) => {
            state.weightage = action.payload;
        },
        setSubscriptionInfo: (state, action) => {
            state.subscriptionInfo = action.payload;
        },

    },
});

/**
 * Actions
 */
export const {
    setSelectPlan,
    setPercentage,
    setPlans,
    setActivePlanPrice,
    setBillingInfo,
    setBillingInvoices,
    setActivePlanWeightage,
    setSubscriptionInfo
 } = brandPlansSlice.actions;

/**
 * Reducers
 */
export const brandPlansReducer = brandPlansSlice.reducer;
