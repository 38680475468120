import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    salesRep: [],
    assignedUnassignedSalesRep: [],
};

const salesRepSlice = createSlice({
    name: 'retailerSlice',
    initialState,
    reducers: {
        setSalesReps: (state, action) => {
            state.salesRep = action.payload;
        },
        setAssignedUnassignedSalesReps: (state, action) => {
            state.assignedUnassignedSalesRep = action.payload;
        },
        resetToInitial: (state) => {
            state.salesRep = [];
        },
    },
});

export const {
    setSalesReps,
    setAssignedUnassignedSalesReps,
    resetToInitial
} = salesRepSlice.actions;

/**
 * Reducers
 */
export const salesRepReducer = salesRepSlice.reducer;
