import React from 'react';
import closeBlackIcon from '../pages/Brand/images/icons/close-icon.svg';
import stockRedAlert from '../assets/images/icons/red-warning.svg';
import { setUploadFileStatusError } from '../redux/Brand/Products/productSlice';
import { useDispatch } from 'react-redux';

const CommonFileUploadStatusError = ({uploadFileStatusError, setShowModal, uploadFileStatusCount}) => {
    const dispatch = useDispatch();
    const closeModal = () => {
        dispatch(setUploadFileStatusError([]));
        setShowModal(false);
    }
    return (
        <div className="popup popup-confirm upload-file-modal active">
            <div className="popup_wrapper">
                <div className="popup_content">
                    <div className="popup-close" 
                    onClick={() => closeModal()}
                    >
            <img className="icon" src={closeBlackIcon} />
          </div>
                    <div className="popup_content-header">
                        <div className="h1">Upload file</div>
                    </div>
                    <div className="form-input">
                        <div className="previewItemConfirm align-items-start">
                        <p className='successText'><u>Products uploaded successfully:</u> {uploadFileStatusError?.successCount}</p>
                        <p className='errorText'><u>Products not uploaded successfully:</u> {uploadFileStatusError?.rejectCount}</p>
                        {
                            uploadFileStatusError?.total_errors?.length > 0 && (
                                <>
                                <div className="errorText">
                                <img
                                    className="icon"
                                src={stockRedAlert}
                                />
                                <span> The file you have uploaded has the following errors </span>
                            </div>
                            <div className="errorList">
                                <ul>
                                    {uploadFileStatusError?.total_errors.map((error, index) => <li key={index}>{error}</li>)}
                                </ul>
                            </div>
                                </>       
                            )
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CommonFileUploadStatusError