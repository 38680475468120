import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  retailerBrandProductsList: [],
  retailerBrandValuesList: [],
  retailerBrandValuesFilter: [],
  retailerPricingFilter: [],
  retailerBrandStatusFilter: [],
  retailerStateFilter: '',
  retailerBrandSearchFilter: '',
  retailerInviteStatusFilter: '',
  retailerNewConnectionRequesting: false,
  retailerNewConnectionRequestSuccess: false,
  retailerNewConnectionRequestError: false,
  retailerBrandProfile: null,
  retailerProducts: [],
  retailerBrandTagsValue: [],
};

const retailerBrandProductsSlice = createSlice({
  name: 'retailerBrandProductsSlice',
  initialState,
  reducers: {
    setRetailerBrandProductsList: (state, action) => {
      state.retailerBrandProductsList = action.payload;
    },
    setRetailerBrandValuesList: (state, action) => {
      state.retailerBrandValuesList = action.payload;
    },
    setRetailerBrandValuesFilter: (state, action) => {
      state.retailerBrandValuesFilter = [...action.payload];
    },
    setRetailerPricingFilter: (state, action) => {
      state.retailerPricingFilter = [...action.payload];
    },
    setRetailerBrandStatusFilter: (state, action) => {
      state.retailerBrandStatusFilter = action.payload;
    },
    setRetailerStateFilter: (state, action) => {
      state.retailerStateFilter = action.payload;
    },
    setRetailerInviteStatusFilter: (state, action) => {
      state.retailerInviteStatusFilter = action.payload;
    },
    resetAllFilter: (state) => {
      state.retailerBrandValuesFilter = [];
      state.retailerPricingFilter = [];
      state.retailerBrandStatusFilter = [];
      state.retailerStateFilter = '';
      state.retailerInviteStatusFilter = '';
    },
    clearBrandValuesFilter: (state) => {
      state.retailerBrandValuesFilter = [];
    },
    clearPricingFilter: (state) => {
      state.retailerPricingFilter = [];
    },
    clearBrandStatusFilter: (state) => {
      state.retailerBrandStatusFilter = [];
    },
    clearStateFilter: (state) => {
      state.retailerStateFilter = '';
    },
    sendRetaileNewConnectionRequest: (state) => {
      state.retailerNewConnectionRequesting = true;
    },
    setRetailerNewConnectionRequestSuccess: (state) => {
      state.retailerNewConnectionRequesting = false;
      state.retailerNewConnectionRequestSuccess = true;
      state.retailerNewConnectionRequestError = false;
    },
    setRetailerNewConnectionRequestError: (state) => {
      state.retailerNewConnectionRequesting = false;
      state.retailerNewConnectionRequestSuccess = false;
      state.retailerNewConnectionRequestError = true;
    },
    resetNewConnectionRequestState: (state) => {
      state.retailerNewConnectionRequesting = false;
      state.retailerNewConnectionRequestSuccess = false;
      state.retailerNewConnectionRequestError = false;
    },
    setRetailerBrandProfile: (state, action) => {
      state.retailerBrandProfile = action.payload;
    },
    setRetailerProducts: (state, action) => {
      state.retailerProducts = action.payload;
    },
    setRetailerBrandTagsValue: (state, action) => {
      state.retailerBrandTagsValue = action.payload;
    },
    setRetailerBrandSearchFilter: (state, action) => {
      state.retailerBrandSearchFilter = action.payload;
    }
  },
});

/**
 * Actions
 */
export const {
  setRetailerBrandProductsList,
  setRetailerBrandValuesList,
  setRetailerBrandValuesFilter,
  setRetailerPricingFilter,
  setRetailerBrandStatusFilter,
  setRetailerStateFilter,
  resetAllFilter,
  clearBrandValuesFilter,
  clearPricingFilter,
  clearBrandStatusFilter,
  clearStateFilter,
  sendRetaileNewConnectionRequest,
  setRetailerNewConnectionRequestSuccess,
  setRetailerNewConnectionRequestError,
  resetNewConnectionRequestState,
  setRetailerBrandProfile,
  setRetailerProducts,
  setRetailerBrandTagsValue,
  setRetailerBrandSearchFilter
} = retailerBrandProductsSlice.actions;

/**
 * Reducer
 */
export const retailerProductReducer = retailerBrandProductsSlice.reducer;
