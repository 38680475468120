import { createSlice } from '@reduxjs/toolkit';
import * as types from '../../../actions/actionTypes';

const initialState = {
  brandProfileDetails: {},
  brandCategory: {},
  brandValues: [],
  brandShopifyStore: {},
  profileCompleted: {
    profile: false,
    preference: false,
    paid: false,
    shipping: false,
    integration: false,
    integration_shop: false,
    plan:false,
    active_plan:{}
  },
};

const brandProfileSlice = createSlice({
  name: 'brandProfile',
  initialState,
  reducers: {
    setBrandProfileDetails: (state, action) => {
      if (action.payload.type === types.BRAND_PROFILE) {
        state.brandProfileDetails = action.payload.data;
      }
    },
    setBrandCategory: (state, action) => {
      if (action.payload.type === types.BRAND_CATEGORY) {
        state.brandCategory = action.payload.data;
      }
    },
    setBrandValues: (state, action) => {
      if (action.payload.type === types.BRAND_VALUES) {
        state.brandValues = action.payload.data;
      }
    },
    setBrandShopifyStore: (state, action) => {
      state.brandShopifyStore = action.payload;
    },
    setProfileCompleted: (state, action) => {
      state.profileCompleted = { ...state.profileCompleted, ...action.payload };
    },
    clearProfileLogout: (state) => {
      state = initialState;
    },
  },
});

/**
 * Actions
 */
export const {
  setBrandProfileDetails,
  setBrandCategory,
  setBrandValues,
  setProfileCompleted,
  setBrandShopifyStore,
  clearProfileLogout,
} = brandProfileSlice.actions;

/**
 * Reducers
 */
export const brandProfileReducer = brandProfileSlice.reducer;
